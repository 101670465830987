<template>
    <page title="请假详情" :hasBack="true" textAlign="left" :backHome="backHome">
        <div class="leave-detail-page">
            <common-block class="leave-msg" title="请假信息">
                <leave-des :leave-msg="leaveMsg" />
                <triangle :type="leaveMsg.status" />
            </common-block>
            <common-block class="process" title="审批流程">
                <common-step :styleApprove="true" :stepList="stepList" />
            </common-block>
            <div class="btn-wrapper" :class="{ center: false }">
                <van-button class="confirm-btn" v-if="leaveMsg.status === 0" round type="warning" @click="rollBackLeave">撤销</van-button>
                <van-button class="confirm-btn" v-if="leaveMsg.status === 0 || leaveMsg.status === 2" round type="primary" @click="changeLeave">修改</van-button>
            </div>
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import CommonBlock from '@/components/commonBlock/CommonBlock';
import LeaveDes from '@/components/leaveDes/LeaveDes';
import Triangle from '@/components/triangle/Triangle';
import CommonStep from '@/components/commonStep/CommonStep';
import commonRequest from '@/services';

import {Button, Toast} from 'vant';
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
    name: 'LeaveDetail',
    components: {
        [Page.name]: Page,
        [CommonBlock.name]: CommonBlock,
        [LeaveDes.name]: LeaveDes,
        [Triangle.name]: Triangle,
        [CommonStep.name]: CommonStep,
        [Button.name]: Button,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const leaveMsg = ref({});
        const stepList = reactive([
            // { title: '进入校门', content: '2021-10-01 07:18:15', id: 1 },
            // { title: '进入校门', content: '2021-10-01 07:18:15', id: 2 },
            // { title: '进入校门', content: '2021-10-01 07:18:15', id: 3 },
        ]);
        commonRequest({ action: 11006, id: route.query.id }).then((res) => {
            if (res.data) {
                leaveMsg.value = res.data;
                stepList[0] = {title: '审批人', content: res.data.teacherName + '老师'};
                stepList[1] = {title: '审批意见'};
                if (res.data.status === 0) {
                    stepList[1].content = '待批假';
                    stepList[1].color= '#EE8A32';
                } else if (res.data.status === 1) {
                    stepList[1].content = '已批假';
                    stepList[1].color= '#1BD188';
                } else if (res.data.status === 4) {
                    stepList[1].content = '已完成';
                    stepList[1].color= '#E64141';
                } else if (res.data.status === 3) {
                    stepList[1].content = '已撤销';
                    stepList[1].color= '#999999';
                } else if (res.data.status === 2) {
                    stepList[1].content = '已驳回';
                    stepList[1].color= '#EE8A32';
                }
                if (res.data.verifyDesc) {
                    stepList[2] = {title: '审批意见', content: res.data.verifyDesc};
                }
                if (res.data.verifyTime) {
                    if (res.data.verifyDesc) {
                        stepList[3] = {title: '审批时间', content: res.data.verifyTime};
                    } else {
                        stepList[2] = {title: '审批时间', content: res.data.verifyTime};
                    }
                }
                console.log(stepList, 'stepList')
            }
        });
        const changeLeave = () => {
            router.push({
                path: '/askLeave',
                query: {id: route.query.id}
            })
        }
        const rollBackLeave = () => {
            commonRequest({ action: 11008, id: route.query.id }).then(() => {
                Toast.success('撤销成功');
                leaveMsg.value.status = 3
            });
        }
        return {
            leaveMsg,
            stepList,
            changeLeave,
            rollBackLeave,
            backHome: route.query.backHome
        };
    },
};
</script>
<style lang="less" scoped>
.leave-detail-page {
    .process {
        /deep/ .common-inner {
            border: none;
            margin-top: -12.5px;
        }
    }
    .btn-wrapper {
        display: flex;
        justify-content: center;
        /*padding: 0 23px;*/
        box-sizing: border-box;
        margin-top: 35px;
        margin-bottom: 20px;
        &.center {
            justify-content: center;
        }
        /deep/ .van-button.van-button--primary {
            padding: 0 47px;
        }
        /deep/ .van-button.van-button--warning {
            padding: 0 47px;
            margin-right: 39px;
        }
    }
}
</style>
